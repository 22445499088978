import { PostingState, initialPostingState } from './posting.state';
import { EPostingsActions, PostingsActions } from './posting.actions';
import { createErrorState, createLoadingState, createSuccessState } from '../app/app.models';

export function postingReducer(
  state = initialPostingState,
  action: PostingsActions
): PostingState {
  switch (action.type) {
    case EPostingsActions.FETCH_POSTING_LIST:
      return {
        ...state,
        postings: createLoadingState(state.postings),
      };
    case EPostingsActions.FETCH_POSTING_LIST_SUCCESS:
      return {
        ...state,
        postings: createSuccessState(action.payload),
      };
    case EPostingsActions.FETCH_POSTING_LIST_ERROR:
      return {
        ...state,
        postings: createErrorState(action.payload.error, {}),
      };
    case EPostingsActions.FETCH_POSTING_LIST_BY_ID:
      return {
        ...state,
        postingDetails: createLoadingState(state.postingDetails),
      };
    case EPostingsActions.FETCH_POSTING_LIST_BY_ID_SUCCESS:
      return {
        ...state,
        postingDetails: createSuccessState(action.payload),
      };
    case EPostingsActions.FETCH_POSTING_LIST_BY_ID_FAILURE:
      return {
        ...state,
        postingDetails: createErrorState(action.payload.error, {}),
      };
    case EPostingsActions.CREATE_NEW_POSTING:
      return {
        ...state,
        createdNewRecord: createLoadingState(state.createdNewRecord)
      };
    case EPostingsActions.CREATE_NEW_POSTING_SUCCESS:
      return {
        ...state,
        createdNewRecord: createSuccessState(action.payload)
      };
    case EPostingsActions.CREATE_NEW_POSTING_FAILURE:
      return {
        ...state,
        createdNewRecord: createErrorState(action.payload.error, {})
      };
    case EPostingsActions.RESET_NEW_POSTING:
      return {
        ...state,
        createdNewRecord: createSuccessState(null)
      };

    case EPostingsActions.FETCH_MENU_ITEM_LIST:
      return {
        ...state,
        menuItemList: createLoadingState(state.menuItemList),
      };
    case EPostingsActions.FETCH_MENU_ITEM_LIST_SUCCESS:
      return {
        ...state,
        menuItemList: createSuccessState(action.payload),
      };
    case EPostingsActions.FETCH_MENU_ITEM_LIST_FAILURE:
      return {
        ...state,
        menuItemList: createErrorState(action.payload.error, {}),
      };

    case EPostingsActions.DELETE_POSTING:
      return {
        ...state,
        deletePosting: createLoadingState(state.deletePosting),
      };
    case EPostingsActions.DELETE_POSTING_SUCCESS:
      return {
        ...state,
        deletePosting: createSuccessState(action.payload),
      };
    case EPostingsActions.DELETE_POSTING_FAILURE:
      return {
        ...state,
        deletePosting: createErrorState(action.payload.error, false),
      };

    case EPostingsActions.FETCH_POSTING_FILTER_LIST:
      return {
        ...state,
        filterData: state.filterData,
      };
    case EPostingsActions.POSTING_FILTER_LIST:
      return {
        ...state,
        ...state.filterData,
        filterData: {
          filterData: action.payload
        },
      };

    case EPostingsActions.UPDATE_POSTING_FILTER_FLAG:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          istriggerSearchApi: action.istriggerSearchApi,
        }
      };

    case EPostingsActions.UPLOAD_TARIFF_POSTING:
      return {
        ...state,
        tariffUploadValidation: createLoadingState(state.tariffUploadValidation),
      };

    case EPostingsActions.RESET_TARIFF_POSTING:
      return {
        ...state,
        tariffUploadValidation: createSuccessState(null),
      };
    case EPostingsActions.UPLOAD_TARIFF_POSTING_SUCCESS:
      return {
        ...state,
        tariffUploadValidation: createSuccessState(action.payload),
      };

    case EPostingsActions.UPLOAD_TARIFF_POSTING_FAILURE:
      return {
        ...state,
        tariffUploadValidation: createErrorState(action.payload.error),
      };

    case EPostingsActions.SET_UPLOAD_TARIFF_POSTING_LOADING:
      return {
        ...state,
        tariffUploadValidation: {
          data: null,
          requestStatus: {
            loading: action.payload.loading,
            httpError: null,
          },
        },
      };

      case EPostingsActions.DOWNLOAD_POSTING_DOCUMENT:
        return {
          ...state,
          downloadPostingDocument: { httpError: null, loading: true, infopostTrackID: action.infopostTrackID },
        };
      case EPostingsActions.DOWNLOAD_POSTING_DOCUMENT_SUCCESS:
        return {
          ...state,
          downloadPostingDocument: { httpError: null, loading: false, infopostTrackID: null },
        };
      case EPostingsActions.DOWNLOAD_POSTING_DOCUMENT_FAILURE:
        return {
          ...state,
          downloadPostingDocument: { httpError: action.payload.error, loading: false, infopostTrackID: null },
        };
  

    default:
      return state;
  }
}
