import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'gms-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
  @Input()
  loading$: Observable<Boolean>;
  @Input()
  loading = false;
}
