import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActivityService } from '@gms/activity-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';

import { HttpResponse } from '@angular/common/http';
import { AuthTicket, TicketService } from '@gms/security-api';
import { MfaChallengeInfo, RolesPageCollection, TokenPair, UserService } from '@gms/user-api';
import { SsoService } from 'app/modules/sso/sso.service';
import { IAppState } from 'app/store/app/app.state';
import { EUsersActions } from 'app/store/users/users.actions';
import { appConfig } from 'config/app-config';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { tap } from 'rxjs/operators/tap';
import { WebsocketService } from 'shared/services/websocket.service';
import {
  EAuthActions,
  FetchRouteConfigs,
  FetchRouteConfigsFailure,
  FetchRouteConfigsSuccess,
  FetchUserFailure,
  FetchUserSuccess,
  FetchWebsocketTicket,
  FetchWebsocketTicketFailure,
  FetchWebsocketTicketSuccess,
  LogIn,
  LogInFailure,
  LogInMfa,
  LogInMfaError,
  LogInSuccess,
  LogOut,
  LogOutWithError,
  RedirectToMfa,
  Refresh,
  RefreshCancel,
  RefreshFailure,
  RefreshSuccess,
  SsoLogIn,
  SsoLogInFailure,
  SsoRedirect,
  UpdatePassword,
  UpdatePasswordFailure,
  UpdatePasswordSuccess,
} from './auth.actions';
import { selectIsADFSLogin, selectIsAuthenticated, selectRefreshToken } from './auth.selectors';

@Injectable()
export class AuthEffects {
  constructor(
    private _actions$: Actions,
    private _router: Router,
    private _store: Store<IAppState>,
    private _activityService: ActivityService,
    private _ssoService: SsoService,
    private _userService: UserService,
    private _webSocketService: WebsocketService,
    private _ticketService: TicketService
  ) {}

  LogIn: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<LogIn>(EAuthActions.LOG_IN),
      map((action: LogIn) => action.payload),
      switchMap(payload =>
        this._userService
          .loginUser(
            {
              username: payload.userId,
              password: payload.password,
              clientId: appConfig.clientId,
            },
            'response'
          )
          .pipe(
            map((response: HttpResponse<TokenPair | MfaChallengeInfo>) => {
              if (response.status === 202) {
                return new RedirectToMfa({
                  mfaChallengeInfo: response.body as MfaChallengeInfo,
                  userId: payload.userId,
                });
              } else {
                const tokenPair = response.body as TokenPair;
                return new LogInSuccess({
                  userId: payload.userId,
                  isADFSLogin: false,
                  accessToken: tokenPair.idToken,
                  refreshToken: tokenPair.refresh,
                  ttl: tokenPair.ttl,
                  roles: tokenPair.roles || [],
                  authResources: tokenPair.resources,
                  isInternal: tokenPair.isInternal,
                });
              }
            }),
            catchError(error => of(new LogInFailure({ error: error })))
          )
      )
    )
  );

  SsoLogIn: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<SsoLogIn>(EAuthActions.SSO_LOG_IN),
      map((action: SsoLogIn) => action.payload),
      switchMap(payload =>
        this._ssoService
          .loginSsoUser({
            code: payload.code,
          })
          .pipe(
            switchMap(loginResponse =>
              this._userService.loginAdfsUser(
                {
                  idToken: loginResponse.id_token,
                  accessToken: loginResponse.access_token,
                  refreshToken: loginResponse.refresh_token,
                },
                'response'
              )
            ),
            map((response: HttpResponse<TokenPair | MfaChallengeInfo>) => {
              if (response.status === 202) {
                throw new Error('MFA Redirect from SSO Login not implemented');
              } else {
                const tokenPair = response.body as TokenPair;
                return new LogInSuccess({
                  userId: tokenPair.email,
                  isADFSLogin: true,
                  accessToken: tokenPair.idToken,
                  refreshToken: tokenPair.refresh,
                  ttl: tokenPair.ttl,
                  roles: tokenPair.roles || [],
                  authResources: tokenPair.resources,
                  isInternal: tokenPair.isInternal,
                });
              }
            }),
            catchError(error => of(new SsoLogInFailure({ error: error })))
          )
      )
    )
  );

  LogInMfa: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<LogInMfa>(EAuthActions.LOG_IN_MFA),
      map((action: LogInMfa) => action.payload),
      switchMap(payload =>
        this._userService
          .loginUserMfa({
            username: payload.userId,
            clientId: appConfig.clientId,
            mfaCode: payload.mfaCode,
            sessionId: payload.sessionId,
          })
          .pipe(
            map(
              (response: TokenPair) =>
                new LogInSuccess({
                  userId: payload.userId,
                  isADFSLogin: false,
                  accessToken: response.idToken,
                  refreshToken: response.refresh,
                  ttl: response.ttl,
                  roles: response.roles || [],
                  authResources: response.resources,
                  isInternal: response.isInternal,
                })
            ),
            catchError(error => of(new LogInMfaError({ error: error })))
          )
      )
    )
  );

  SsoLogInFailure: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.SSO_LOG_IN_FAILURE),
        tap(() => {
          this._router.navigateByUrl('/sso');
        })
      ),
    { dispatch: false }
  );

  LogInSuccess: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType(EAuthActions.LOG_IN_SUCCESS),
      map((action: LogInSuccess) => action.payload),
      switchMap(payload =>
        this._userService.getUserProfile().pipe(
          map(response => new FetchUserSuccess({ user: response, redirectToHome: true })),
          catchError(error => of(new FetchUserFailure({ error: error })))
        )
      )
    )
  );

  SsoRedirect: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.SSO_REDIRECT),
        map((action: SsoRedirect) => action.payload),
        map(payload => {
          window.location.href = `${
            appConfig.cognito.uri
          }/oauth2/authorize?identity_provider=ADFS&response_type=code&client_id=${
            appConfig.cognito.adfsClientId
          }&redirect_uri=${window.location.origin}${appConfig.cognito.redirectPathname}`;
        })
      ),
    { dispatch: false }
  );

  InvalidSsoUserId: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.INVALID_SSO_USER_ID),
        tap(() => {
          this._router.navigateByUrl('/login');
        })
      ),
    { dispatch: false }
  );

  UnauthorizedUser: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.UNAUTHORIZED_USER),
        tap(() => {
          this._router.navigateByUrl('/unauthorized');
        })
      ),
    { dispatch: false }
  );

  FetchUserSuccess: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.FETCH_USER_SUCCESS),
        //ng15 investigate action of never? had to type as any.
        tap((action: any) => {
          window.dispatchEvent(new Event('FetchUserSuccess'));
          if (action.payload.redirectToHome) {
            this._router.navigateByUrl('/home');
          }
        })
      ),
    { dispatch: false }
  );

  Refresh: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<Refresh>(EAuthActions.REFRESH),
      switchMap(() => this._store.pipe(select(selectRefreshToken))),
      withLatestFrom(
        this._store.pipe(select(selectIsAuthenticated)),
        this._store.pipe(select(selectIsADFSLogin))
      ),
      switchMap(([refreshToken, isAuthenticated, isADFSLogin]) =>
        this._userService.refresh({ refresh: refreshToken }, isADFSLogin).pipe(
          map(
            response =>
              new RefreshSuccess({
                accessToken: response.idToken,
                ttl: response.ttl,
              })
          ),
          catchError(error => {
            // log user out immediately, so they don't have to wait for all api requests to finish
            // don't throw an error if the user is not authenticated
            if (isAuthenticated) {
              return of(new LogOutWithError({ error: error }));
            }
            return of(new LogOut());
          })
        )
      )
    )
  );

  LogOut: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.LOG_OUT),
        tap(() => {
          this._webSocketService.reset();
          this._router.navigateByUrl('/login');
        })
      ),
    { dispatch: false }
  );

  LogOutWithError: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.LOG_OUT_WITH_ERROR),
        tap(() => {
          this._webSocketService.reset();
          this._router.navigateByUrl('/login');
        })
      ),
    { dispatch: false }
  );

  RedirectToMfa: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.REDIRECT_TO_MFA),
        tap(() => {
          this._router.navigateByUrl('/login/mfa');
        })
      ),
    { dispatch: false }
  );

  Lock: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.LOCK),
        tap(() => {
          this._router.navigateByUrl('/account-locked');
        })
      ),
    { dispatch: false }
  );

  AcceptCookies: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.ACCEPT_COOKIES),
        tap(() => {
          this._activityService
            .createActivityLog({ activityType: 'UserCookieAcceptance', key: 'IPAddress' })
            .subscribe();
        })
      ),
    { dispatch: false }
  );

  UpdatePassword: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<UpdatePassword>(EAuthActions.UPDATE_PASSWORD),
      map((action: UpdatePassword) => action.payload),
      switchMap(payload =>
        this._userService.resetPassword(payload).pipe(
          map(() => new UpdatePasswordSuccess()),
          catchError(error => of(new UpdatePasswordFailure({ error: error })))
        )
      )
    )
  );

  UpdatePasswordSuccess: Observable<any> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EAuthActions.UPDATE_PASSWORD_SUCCESS),
        tap(() => {
          this._router.navigateByUrl('/login');
        })
      ),
    { dispatch: false }
  );

  FetchRouteRoleConfigsLoading: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType(EAuthActions.LOG_IN_SUCCESS),
      map(() => new FetchRouteConfigs())
    )
  );

  FetchRouteRoleConfigs: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType(EAuthActions.LOG_IN_SUCCESS),
      switchMap(() =>
        this._userService.getRolePages().pipe(
          map(
            (rolesPageCollection: RolesPageCollection) =>
              new FetchRouteConfigsSuccess({ rolesPages: rolesPageCollection.rolesPages })
          ),
          catchError(error => of(new FetchRouteConfigsFailure({ error: error })))
        )
      )
    )
  );

  UserProfileUpdated: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType(EUsersActions.UpdateUserProfileSuccess),
      switchMap(payload =>
        this._userService.getUserProfile().pipe(
          map(response => new FetchUserSuccess({ user: response, redirectToHome: false })),
          catchError(error => of(new FetchUserFailure({ error: error })))
        )
      )
    )
  );

  FetchWebsocketTicket: Observable<any> = createEffect(() =>
    this._actions$.pipe(
      ofType<FetchWebsocketTicket>(EAuthActions.FETCH_WEBSOCKET_TICKET),
      map((action: FetchWebsocketTicket) => action.payload),
      switchMap(payload =>
        this._ticketService.issueTicket({ token: payload.accessToken }).pipe(
          map((ticket: AuthTicket) => new FetchWebsocketTicketSuccess({ ticket })),
          catchError(error => of(new FetchWebsocketTicketFailure({ error: error })))
        )
      )
    )
  );
}
