import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { fetchInfoPostTspInfo } from "app/store/infopost/infopost.actions";
import { getFeatureFlageInfo as infopostFeatureFlag, selectAllInfoPostTSP, selectAllInfoPostTSPLoading } from "app/store/infopost/infopost.selectors";
import { IInfoPostState } from "app/store/infopost/infopost.state";
import { filter, map, switchMap, take } from "rxjs/operators";
import { InformationalWebLabels } from "app/modules/infopost/informational-posting-web.utils";
import { upperNavMenuConfigs as _upperNavMenuConfigs, NavConfig } from 'app/components/app-common-views/sidebar-nav/nav-menus.constants';
import { FeatureFlag } from "@gms/reporting-api";
import { LABELS } from "shared/consts/labels.const";
import { IAppState } from "app/store/app/app.state";
import { FetchTsps } from "app/store/tsp/tsps.actions";
import { tspLoadingStatus, getFeatureFlageInfo } from "app/store/tsp/tsps.selectors";

export const featureFlagGuard: CanActivateFn = (route, state) => {
  const store = inject(Store<IInfoPostState>);
  const router = inject(Router);

  return store.select(selectAllInfoPostTSP).pipe(
    take(1),
    switchMap(infoPostTspInfo => {
      if (!infoPostTspInfo) {
        store.dispatch(fetchInfoPostTspInfo());
      }
      return store.select(selectAllInfoPostTSPLoading).pipe(
        filter(loading => !loading),
        switchMap(() =>
          store.select(infopostFeatureFlag).pipe(
            map(flags => {
              const infoPostFlag = flags?.find(flag => flag.name === InformationalWebLabels.WEBSITEFEATUREFLAG);
              if (infoPostFlag && infoPostFlag.isEnabled) {
                return true;
              }
              router.navigate([InformationalWebLabels.LOGINROUTES]);
              return false;
            })
          )
        )
      );
    })
  );
};

export const allowCBH: CanActivateFn = (route, state) => {
  const store = inject(Store<IInfoPostState>);
  const router = inject(Router);

  return store.select(selectAllInfoPostTSP).pipe(
    take(1),
    switchMap(infoPostTspInfo => {
      if (!infoPostTspInfo) {
        store.dispatch(fetchInfoPostTspInfo());
      }
      return store.select(selectAllInfoPostTSPLoading).pipe(
        filter(loading => !loading),
        switchMap(() =>
          store.select(infopostFeatureFlag).pipe(
            map(flags => {
              const CBHFlag = flags?.find(flag => flag.name === InformationalWebLabels.CBHFEATUREFLAG);
              if (CBHFlag && CBHFlag.isEnabled) {
                return true;
              }
              router.navigate([InformationalWebLabels.LOGINROUTES]);
              return false;
            })
          )
        )
      );
    })
  );
};

export const FeatureFlagGuard: CanActivateFn = (route, state) => {
  const store = inject(Store<IAppState>);
  const router = inject(Router);
  const upperNavMenuConfigs =  _upperNavMenuConfigs
  store.dispatch(new FetchTsps());

  return store.select(tspLoadingStatus).pipe(
    filter(loading => !loading),
    switchMap(() =>
      store.select(getFeatureFlageInfo).pipe(
        map(flags => {
          const disabledFlag = findDisabledFlag(upperNavMenuConfigs, flags,route.data.aclResource);
          if (disabledFlag) {
            router.navigate([InformationalWebLabels.LOGINROUTES]);
            return false;
          }
          return true;
        })
      )
    )
  );
};
const findDisabledFlag = (menuItems: NavConfig[], flags: FeatureFlag[],url:string): boolean => {
  let disabledFeatureFlag = false;
  menuItems.forEach(item => {
    if (item.id === LABELS.MENU_TYPE_ADMINISTRATION && item.subMenuItems.length > 0) {
      item.subMenuItems.forEach(subItem => {
        if (subItem.id === LABELS.MENU_TYPE_INFORMATIONAL_POSTINGS && subItem.subMenuItems?.length > 0) {
          subItem.subMenuItems.forEach(subSubItem => {
            const featureFlag = flags.find(flag => flag.name === subSubItem.featureName);
            if (featureFlag && !featureFlag.isEnabled) {
              if(subSubItem.route===url){
               disabledFeatureFlag = true;
              }
            }
          });
        }
      });
    }
  });
  return disabledFeatureFlag;
};